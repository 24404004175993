.sss {
	height: 0;
	margin: 0; 
	padding: 0;
	position: relative;
	display: block;
}

.ssslide {
	width: 100%;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 0;
	left: 0;
	display: none;
}

.ssslide img {
	max-width: 100%;
	height: auto;
	margin: 0;
	padding: 0;
	position: relative;
}

.sssnext, .sssprev {
	width: 16px;
	height: 100%;
	margin: 0;
	position: absolute;
	top: 0;
	background: url('images/arr.png') no-repeat;
}

.sssprev {
	left: -3%;
	background-position: 0 50%;
}

.sssnext {
	right: -3%;
	background-position: -16px 50%;
}

.sssprev:hover, .sssnext:hover {
	cursor: pointer;
}