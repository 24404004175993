.icon24:before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  top: torem(4);
  margin-right: torem(10);
  background-image: url("../images/site_layout/icons.png");
  background-repeat: no-repeat;
}
.icon24-tel:before {
  background-position: 0 0;
}
.icon24-mail:before {
  background-position: -24px 0;
}
.icon24-devis:before {
  background-position: -48px 0;
}
