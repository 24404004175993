@media screen and (max-width: 1200px) {
  .wrapper {
    width: auto;
    margin: 0 auto;
  }
  main {
    padding-left: 0;
  }
  .points-forts {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 18px;
    line-height: 1.125rem;
  }
  .devis,
  .devis-demande {
    padding: 0 26px;
    padding: 0 1.625rem;
  }
  .devis:before {
    top: -19px;
    top: -1.1875rem;
  }
  .plan-interactif > .head {
    padding: 8px;
    padding: 0.5rem;
  }
  .table-salles th {
    min-width: auto;
    max-width: auto;
    padding: 14px;
  }
  footer > .wrapper > .col {
    padding-right: 20px;
  }
  body > .nav-presta a {
    float: left;
    width: 25%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 2.5vw;
  }
  body > .nav-presta:after {
    content: "";
    display: block;
    clear: both;
  }
}
@media screen and (max-width: 940px) {
  .header-content .logo-tab {
    float: none;
    text-align: center;
  }
  #header-slides {
    min-height: 335px;
    min-height: 20.9375rem;
  }
  #header-slides.header-slides-large {
    min-height: 380px;
    min-height: 23.75rem;
  }
  #header-slides .header-slide h2,
  #header-slides .header-slide-mask h2 {
    float: none;
    position: absolute;
    bottom: 19px;
    bottom: 1.1875rem;
    left: 0;
    right: 0;
    margin: 32px 0;
    margin: 2rem 0;
    text-align: center;
    font-weight: 900;
    font-size: 36px;
    font-size: 2.25rem;
  }
  body > .nav-main {
    height: auto;
  }
  body > .nav-main a {
    display: block;
    margin: 13px 0;
    margin: 0.8125rem 0;
    padding: 0;
    border: none;
  }
  body > .nav-main a:first-child,
  body > .nav-main a:last-child {
    padding: 0;
    border: none;
  }
  .devis {
    margin-top: 30px;
    margin-top: 1.875rem;
  }
  .devis,
  .devis-demande {
    text-align: center;
  }
  .devis:before,
  .devis-demande:after {
    display: none;
  }
  .points-forts {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 26px;
    line-height: 1.625rem;
  }
  .partners {
    width: 360px;
    width: 22.5rem;
    margin: 0 auto;
  }
  .table-salles {
    margin: 0 auto;
  }
  .salle-tarifs {
    width: 100%;
  }
  main figure {
    text-align: center;
  }
  body > .wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  body > .wrapper > .sidemenu {
    float: none;
    width: 100%;
    max-width: 455px;
    max-width: 28.4375rem;
    margin: 20px auto 0 auto;
    margin: 1.25rem auto 0 auto;
    padding-right: 0;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  body > .wrapper > .sidemenu:before {
    content: "";
    display: block;
    width: 50%;
    height: 2px;
    height: 0.125rem;
    margin: 0 auto;
    background-color: #ddd;
  }
  body > .wrapper > .sidemenu figure {
    text-align: center;
  }
  body > .wrapper > .sidemenu p {
    text-align: center;
  }
  body > .wrapper > main {
    width: auto;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  body > .wrapper > main > .main-inner-right {
    padding: 0;
    width: auto;
    float: none;
  }
  footer {
    text-align: center;
    font-size: 20px;
    font-size: 1.25rem;
    padding-bottom: 16px;
    padding-bottom: 1rem;
  }
  footer a:link,
  footer a:visited {
    display: inline;
  }
  footer .guide {
    float: none;
  }
  footer > .wrapper {
    width: 400px;
    width: 25rem;
    margin: 0 auto;
  }
  footer > .wrapper > .col {
    padding-right: 0;
  }
  footer .col-guide,
  footer .col-salles,
  footer .col-prestations,
  footer .col-acces {
    width: auto;
    float: none;
  }
  footer .col-guide a:not(:last-child):after,
  footer .col-salles a:not(:last-child):after,
  footer .col-prestations a:not(:last-child):after,
  footer .col-acces a:not(:last-child):after {
    content: " / ";
  }
  footer .col-acces {
    margin-top: 32px;
    margin-top: 2rem;
  }
  footer h3 {
    font-size: 22px;
    font-size: 1.375rem;
    text-align: center;
    margin: 32px 0 16px 0;
    margin: 2rem 0 1rem 0;
  }
  footer .col-guide h3 {
    margin: 16px 0;
    margin: 1rem 0;
  }
  footer .authors {
    position: static;
    font-size: 0.8em;
    text-align: center;
  }
  footer .authors li {
    margin-bottom: 1em;
  }
  .col.col50,
  .col.col33 {
    width: 100%;
  }
  .col > .col-inner {
    padding: 0;
  }
  .pictos {
    display: block;
  }
  .button-fat {
    max-width: 480px;
    max-width: 30rem;
    margin: 0 auto;
  }
}
@media screen and (max-width: 650px) {
  h1 {
    text-align: center;
  }
  .table-salles-wrapper {
    width: auto;
    overflow-x: scroll;
    overflow-y: none;
    margin: 0;
  }
  .table-salles {
    width: 593px;
    margin: 0;
  }
  .salle-tarifs {
    display: block;
    width: auto;
  }
  .salle-tarifs > div {
    display: block;
  }
  .salle-tarifs .salle-tarifs-horaires,
  .salle-tarifs .salle-tarifs-forfait {
    width: auto;
  }
  .salle-tarifs .salle-tarifs-horaires {
    padding: 16px 32px;
    padding: 1rem 2rem;
  }
  main .plan-interactif > .canvas-container {
    height: 550px;
    height: 34.375rem;
  }
}
@media screen and (max-width: 440px) {
  .partners {
    width: auto;
    margin: 0;
  }
  .points-forts a:link,
  .points-forts a:visited {
    text-align: left;
  }
  .esm-access-wrapper > .link-pdf {
    position: static;
    display: block;
    margin-bottom: 1em;
  }
  .esm-access-wrapper > .link-pdf > img {
    width: auto;
  }
  .esm-access-wrapper table {
    width: auto;
    display: block;
  }
  .esm-access-wrapper table th,
  .esm-access-wrapper table td {
    display: block;
    padding: 16px;
    padding: 1rem;
  }
  .esm-access-wrapper table th {
    width: auto;
    text-align: center;
  }
  .button-fat {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 24px 16px;
    padding: 1.5rem 1rem;
  }
  body > .nav-presta a {
    display: block;
    width: auto;
    height: auto;
    padding: 6px 0;
    padding: 0.375rem 0;
    float: none;
    font-size: 16px;
    font-size: 1rem;
    line-height: normal;
    border: none;
  }
  body > .nav-presta a:link,
  body > .nav-presta a:visited {
    color: #000;
  }
  body > .nav-presta a:link::after,
  body > .nav-presta a:visited::after {
    display: none;
  }
  body > .nav-presta a.nav-presta-tarifs {
    background-image: none;
  }
  body > .nav-presta a.nav-presta-restauration {
    background-image: none;
  }
  body > .nav-presta a.nav-presta-equipements {
    background-image: none;
  }
  body > .nav-presta a.nav-presta-hebergements {
    background-image: none;
  }
  .hotel .hotel-images {
    float: none;
    margin: 0 0 2rem 0;
    padding-top: 1rem;
  }
  .hotel .hotel-description {
    text-align: center;
  }
  .hotel:nth-child(2n+1) {
    background-color: #f2f2f2;
  }
  footer {
    padding-bottom: 0;
  }
  footer > .wrapper {
    width: auto;
  }
}
